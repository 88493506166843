import _ from 'lodash'
import axios from '../../common/axios.js'

export default {
	data() {
		return {
			show_choices: false,

			departement: null,
			commune: null,
			commune_absorbee: null,
			section: null,
			parcelle: null,

			departements: [{label: 'Canada', code: 'ca'}],
			communes: [],
			communes_absorbees: [],
			sections: [],
			parcelles: [],
		}
	},
	created(){
		let self = this
		axios.get('api=inex/parcelles/')
			.then(function (response) {
				self.departements = response.data.results
			}).catch(function (error) {

			})
	},
	methods: {
		resetQ(){
			this.departement      = null;
			this.commune          = null;
			this.commune_absorbee = null;
			this.section          = null;
			this.parcelle         = null;
		},
		search(){
			if(this.parcelle && this.parcelle.value) this.$store.dispatch('setParcelleOnId', this.parcelle.value);
		}
	},
	watch:{
    	departement(nouveau, ancien){
			let self = this
			if(!self.departement) return
    		this.commune          = null;
    		this.commune_absorbee = null;
    		this.section          = null;
    		this.parcelle         = null;
    		self.communes = [{"name":this.$t('loading'), "value":0}]
    		axios.get('api=inex/parcelles/?code_dep='+self.departement.value)
    			.then(function (response) {
    				self.communes = response.data.results
    			}).catch(function (error) {

    			})
    	},
    	commune(nouveau, ancien){
			let self = this
			if(!self.departement || !self.commune) return
    		this.commune_absorbee = null;
    		this.section          = null;
    		this.parcelle         = null;
    		self.communes_absorbees = [{"name":this.$t('loading'), "value":0}]
    		axios.get('api=inex/parcelles/?code_dep='+self.departement.value+'&code_com='+self.commune.value)
    			.then(function (response) {
    				self.communes_absorbees = response.data.results
    			}).catch(function (error) {

    			})
    	},
    	commune_absorbee(nouveau, ancien){
			let self = this
			if(!self.departement || !self.commune || !self.commune_absorbee) return
    		this.section          = null;
    		this.parcelle         = null;
    		self.sections = [{"name":this.$t('loading'), "value":0}]
    		axios.get('api=inex/parcelles/?code_dep='+self.departement.value+'&code_com='+self.commune.value+'&com_abs='+self.commune_absorbee.value)
    			.then(function (response) {
    				self.sections = response.data.results
    			}).catch(function (error) {

    			})
    	},
    	section(nouveau, ancien){
			let self = this
			if(!self.departement || !self.commune || !self.commune_absorbee || !self.section) return
    		this.parcelle         = null;
    		self.parcelles = [{"name":this.$t('loading'), "value":0}]
    		axios.get('api=inex/parcelles/?code_dep='+self.departement.value+'&code_com='+self.commune.value+'&com_abs='+self.commune_absorbee.value+'&section='+self.section.value)
    			.then(function (response) {
    				self.parcelles = response.data.results
    			}).catch(function (error) {

    			})
    	}
    },
	computed:{

	},
	components:{
	}
}